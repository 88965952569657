import React, { useEffect, useRef, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import {BigNumber, ethers, Wallet} from 'ethers';
import * as abi from './contracts/abi/mallows.json';
import detectEthereumProvider from '@metamask/detect-provider';
import { Avatar, Box, Button, Container, createTheme, Grid, makeStyles, Tab, Tabs, ThemeProvider, Typography } from '@material-ui/core';
import { SpeedDial, SpeedDialAction, SpeedDialIcon, TabPanel } from '@material-ui/lab';
import { AccountBalanceWallet, BlurOn, ContactsOutlined, Telegram } from '@material-ui/icons';
import DragMove from './DragMove';
import * as THREE from 'three';

//@ts-expect-error
import Image from 'react-graceful-image';


import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  Redirect
} from "react-router-dom";
import { render } from '@testing-library/react';

const useStyles = makeStyles( (theme) => ({
  root: {
    position: 'relative',
    top: 0,
    left: 0,
    minWidth: '100vw',
    minHeight: '100vh',
    //background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
  },
  byoaButton: {
    //change
  },
  algo: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    boxShadow: '0 0 4px 4px #333',
    borderRadius: 10,
    display: 'inline-block'
  },
  tooltip: {
    backgroundColor: 'blue'
  },
  normalContent: {
    
    pointerEvents: 'all',
    paddingBottom: 40
  },
  showcasePanel: {
    alignItems: 'center',
    textAlign: 'center',
    color: 'white'
  },
  normalPage: {
    color: 'black',
    background: 'inherit'
  },
  connectWalletButton: {
    marginTop: 10
  },
  nftDisplayContainer: {
    marginTop: 10
  },
  speedDial: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    zIndex: 1000000000
  },
  hudContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    //background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    //backgroundColor: 'rgba(30,30,30,0.40)',
    zIndex: 100000,
    pointerEvents: 'none',
  },
  threeShowcase: {
    height: 400,
    width: 400,
    margin: '0 auto'
  }
}));

const theme = createTheme({
  typography: {
    fontFamily: [
      'Chivo',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontWeight: 900
    }
  },
});

interface BYOAElement {
  contractAddress?: string;
  address?: string;
  imageURI?: string;
  name : string;
  description : string;
  byoaURI: string;
  isLoaded: boolean;
  tokenId: number;
  process? : (input : any, addressOwner : string, ethersjs : any, ethersProvider : any, jsonRPCProvider : any) => any ;
}

function App() {
  const classes = useStyles();
  const [accountAddress, setAccountAddress] = useState('');
  const [tokenURI, setTokenURI] = useState('');
  const [metadataURI, setMetadataURI] = useState('');
  const [metadataJSON, setMetadataJSON] = useState<any>({});
  const [mallowImageURI, setMallowImageURI] = useState<string | null>(null);
  const [inputData, setInputData] = useState<string>("Hello and welcome to byoa");
  const [byoaLoaded, setByoaLoaded] = useState<boolean>(false);
  const [byoaURI, setByoaURI] = useState<string | null>(null);
  const [mallows, setMallows] = useState<any[]>([]);
  const [lastLoadedIndex, setLastLoadedIndex] = useState(0);
  const [openDial, setOpenDial] = useState(false);
  const [hiddenDial, setHiddenDial] = useState(false);
  const [byoaElements, setByoaElements] = useState<BYOAElement[]>([]);
  const [translateDial, setTranslateDial] = useState({
    x: 0,
    y: 0
  });
  const [maxMallows, setMaxMallows] = useState<number>(0);
  const [algo, setAlgo] = useState<string>("");

  const [dialDirection, setDialDirection] = useState<"left" | "right" | "up" | "down" | undefined>("up");

  const jrpcProvider = new ethers.providers.JsonRpcProvider('https://eth-mainnet.alchemyapi.io/v2/Uo717K-DDAxlSM5gXM-zgv678k0aMZH5', 'mainnet');

  const sceneRenderEl = useRef<HTMLDivElement>(null);
  const [displayTabValue, setDisplayTabValue] = useState<number>(0);

  useEffect( () => {
    gatherDetails(safeTokenId(getQueryStringVal('tokenId')));
    setTimeout(() => {
      if(byoaElements.length >= 1)
        loadBYOA(byoaElements[0]);
    }, 5000)
  }, []);
  // tldr
  /*
  useEffect( () => {
    
    if (sceneRenderEl.current === null) return;
    if (mallowImageURI === null) return;
    let sceneWidth = sceneRenderEl.current.clientWidth;
    let sceneHeight = sceneRenderEl.current.clientWidth;


    const scene = new THREE.Scene();
    var camera = new THREE.PerspectiveCamera( 75, sceneWidth/sceneHeight, 0.1, 400 );
    var renderer = new THREE.WebGLRenderer({alpha: true});
    renderer.setSize( 400, 400 );
    
    // document.body.appendChild( renderer.domElement );
    // use ref as a mount point of the Three.js scene instead of the document.body
    sceneRenderEl.current.appendChild( renderer.domElement );
    var pivot = new THREE.Group();

    
    var loader = new THREE.TextureLoader();
    let planeMesh : THREE.Mesh;

    
    let attempts = 0;
    let loadFunc = (attempts : number) => {
      console.log(`Load Attempt ${attempts}`);
      if (attempts == 5) return;
      loader.load(mallowImageURI,
        function (texture) {
            var planeGeom = new THREE.PlaneGeometry(100,100);
            var planeMat = new THREE.MeshBasicMaterial(
                                  {color: 0xffffff,
                                    map: texture} );
            planeMesh = new THREE.Mesh(planeGeom, planeMat);
            planeMesh.position.set(0,0,51);
            pivot.add(planeMesh);

            // repeat texture mapping on right panel
            var planeMeshR = planeMesh.clone();
            //var dist = 5*Math.cos(Math.PI/4);
            planeMeshR.position.set(0, 0, 49);
            planeMeshR.rotation.y = -Math.PI;
            pivot.add(planeMeshR);

            // repeat texture mapping on left panel
            var planeMeshL = planeMesh.clone();
            //planeMeshL.position.set(-5-dist, 0, dist);
            planeMeshL.rotation.y = Math.PI/4;
            //scene.add(planeMeshL);
            
        }, undefined, ( err )=> {
          console.log(`Got 3js error: ${err}`)
          loadFunc(attempts++);
        } );
    }
    loadFunc(0);
          

          
          
              
        

            var geometry = new THREE.BoxGeometry(100, 100, 100, 1, 1, 1);
            var material = new THREE.MeshBasicMaterial({color: 0x000000, wireframe: false, depthTest: true});
            var cube = new THREE.Mesh(geometry, material);
            const edges = new THREE.EdgesGeometry( geometry );
            //const line = new THREE.LineSegme nts( edges, new THREE.LineBasicMaterial( {linewidth: 1000, color: 0x51eb2b } ) );
            const line = new THREE.LineSegments( edges, new THREE.LineBasicMaterial ( {linewidth: 2, color: 0x51eb2b } ) );
            
            //pivot.add(cube);
            pivot.add( line );
            

       scene.add(pivot);


    camera.position.z = 145;
    var animate = function () {
      requestAnimationFrame( animate );
      
      if (planeMesh) {
        //planeMesh.rotation.x += 0.003;
        //pivot.rotation.x += 0.003;
        pivot.rotation.y += 0.0075;
  //      planeMesh.rotation.x += 0.001;
    //    planeMesh.rotation.y += 0.001;
        //planeMesh.rotation.z += 0.003;
      //  cube.rotation.x += 0.001;
//        cube.rotation.y += 0.001;
      }
      

      
      renderer.render( scene, camera );
    };
    animate();
  }, [mallowImageURI]);
  */

  const ipfsToPinata = (ipfsURI : string) : string => {
    if(ipfsURI == undefined) return "";
    let comps = ipfsURI.split('ipfs');
    return `https://gateway.pinata.cloud/ipfs/${comps[comps.length-1].replaceAll('://','').replaceAll('//','')}`;
  }

  
  const addFn = (name : string, desc :string, process : (input : any, addressOwner : string, ethersjs : any, ethersProvider : any, jsonRPCProvider : any) => any ) => {
      let items = [...byoaElements];
      let replacement = hackyHold;
      replacement.name = name;
      replacement.description = desc;
      replacement.process = process;
      items[hackyIndex] = replacement;
      setByoaElements(items);
    
  };

 

  const grabNFTs = async (accountAddress : string, tokenID : string) : Promise<string> => {
    let provider = ethers.getDefaultProvider('https://eth-mainnet.alchemyapi.io/v2/Uo717K-DDAxlSM5gXM-zgv678k0aMZH5');
    let contractAddress = '0xf20EaeAe0390803B1A7c8Ab4c506870B81E1048e';
    // @ts-ignore
    let contract = new ethers.Contract(contractAddress, abi['abi'], provider);
    try {
      
      let ownedNFTs = [tokenID]
      let allByoas = [];
      for(var i = 0; i < ownedNFTs.length; i ++) {
        let tokenID = parseInt(ownedNFTs[i]);
        let turi = await contract.tokenURI(tokenID);
        let supply = await contract.totalSupply();
        setMaxMallows(supply.toNumber());
        let pinataLink = ipfsToPinata(turi);
        const response = await fetch(pinataLink);
        const json = await response.json();
        const byoaURI = ipfsToPinata(json['byoa']);
        setMallowImageURI(ipfsToPinata(json['image']));
        json.attributes.map( (el :any) => {
          if(el['trait_type'] === 'algo') {
            setAlgo(el['value'])
          }
        })
        setMetadataJSON(json);

        let byoaEl :BYOAElement = {
          name: `BYOA ${tokenID}`,
          description: `BYOA ${tokenID}`,
          byoaURI: byoaURI,
          imageURI: ipfsToPinata(json['image']),
          isLoaded: false,
          tokenId: tokenID
        }

        allByoas.push(byoaEl);
        
      }
      loadBYOA(allByoas[0])
      setByoaElements(allByoas)
      return "";
    } catch (error) {
      return "";
    }
    
  };

  let hackyHold : BYOAElement;
  let hackyIndex : number;

  const loadBYOA = async (byoaEl : BYOAElement) => {
    if(!byoaEl.isLoaded) {
      // Unregister the last handler
      //@ts-ignore
      window.register = undefined;
      let fileref=document.createElement('script');
      fileref.setAttribute('type','text/javascript');
      fileref.setAttribute('src', `${byoaEl.byoaURI}?cachebust=${Math.floor(Math.random()*1000)}`);
      document.getElementsByTagName('head')[0].appendChild(fileref);
      
      // findElIndex
      let bi = 0;
      for(var i = 0; i < byoaElements.length; i ++) {
        if(byoaEl.tokenId === byoaElements[i].tokenId) {
          bi = i;
        }
      }
      setLastLoadedIndex(bi);

      let items = [...byoaElements];
      let replacement = {...byoaElements[bi]}
      replacement.isLoaded = true;
      hackyHold = replacement;
      hackyIndex = bi;
      items[bi] = replacement;
      setByoaElements(items);

      // Now Register
      let iv = setInterval(async () => {
        // @ts-ignore
        if (!window.register) return;

        clearInterval(iv);

        // Set the processing method
        // @ts-ignore
        window.register({
          addFn: addFn
        })

      })
      
    }
  };

  // Initial Entry into loading all of our wallet etails
  const gatherDetails = async (tokenID : string) => {
    // Grabs all NFTs
    await grabNFTs("", tokenID);
  };

  const getQuery = () => {
    if (typeof window !== 'undefined') {
      return new URLSearchParams(window.location.search);
    }
    return new URLSearchParams();
  };
  
  const getQueryStringVal = (key: string): string=> {
    let val = getQuery().get(key);
    if (val === null) return '0';
    return val;
  };

  const safeTokenId = (t : string) : string => {
    if (parseInt(t) !== NaN) {
      return `${Math.max(0, parseInt(t))}`
    }
    return '0';
  }

  

  return (
    <ThemeProvider theme={theme}>
      
    <Box className={classes.root}>
      <Container className={classes.normalContent}>
          <Box className={classes.showcasePanel}>
            <Typography variant="h1" style={{marginBottom: 20, paddingBottom: 10, borderBottomStyle: 'solid',borderBottomWidth: 4, borderBottomColor: 'white'}}>mallow showcase</Typography>
            <Box style={{textAlign: 'left'}}>
              <Typography variant="h4">mallow-verse sandbox</Typography>
              <p>Mallows are the mascot of <b>byoa</b> (bring your own algorithm), a new decentralized app ecosystem where the apps are stored inside NFTs.</p>
            </Box>


            <Grid container>
              <Grid item sm={8}>
                <Typography variant="h4">Mallow #{safeTokenId(getQueryStringVal('tokenId'))}</Typography>
                
                
                  
                
                {displayTabValue == 0 && (
                  <Box>
                
                { mallowImageURI && (
                  <Image
                      src={`${mallowImageURI}`}
                      style={{
                        width: 400,
                        boxShadow: '0 0 4px 4px #ccc'
                      }}
                      alt='My awesome image'
                  />
                )}
                </Box>
                )}
                <Box>
                        <Box>
                          <Typography variant="h5" className={classes.algo}>algorithm: <b>{`${algo}`}</b></Typography>
                        </Box>
                          <Box style={{margin: '0 auto', display: 'inline-block', marginTop: 15}}>
                          {(metadataJSON && metadataJSON.attributes) && (metadataJSON.attributes.map( (attr : any) => (
                            
                            <Box style={{textAlign: 'left'}}>
                              <Typography variant="body2"><b>{attr['trait_type']}</b>: {attr['value']}</Typography>
                            </Box>
                            
                          )))}
                          </Box>
                        
                      </Box>
              </Grid>
              <Grid sm={4} style={{textAlign: 'left'}}>
                <p>Here you can play around with any mallow in the mallow-verse.</p>
                <p>Click the "Run" button to see the algorithm stored inside Mallow #{safeTokenId(getQueryStringVal('tokenId'))}.</p>

                <p>Some effects happen right away, others wait for the next ethereum block. So be patient with your mallow :)</p>

                <p>Refresh the page to reset everything.</p>
                {maxMallows !== 0 && (
                  <Button variant="contained" style={{
                    pointerEvents: 'all'
                  }} href={`?tokenId=${Math.floor(Math.random()*maxMallows)}`}>Try Another Mallow!</Button>
                )}

                <hr/>
                <Button target="_blank" variant='outlined' href={'https://github.com/web3-byoa/whitepaper/blob/main/byoa%20whitepaper.pdf'}>View Whitepaper</Button>
                <br/>
                <br/>
                <Button target="_blank" variant='outlined' href={'https://mint.mallows.xyz'}>Mint Mallows</Button>
                <br/>
                <br/>
                <Button target="_blank" variant='outlined' href={'https://www.mallows.xyz/mallow-verse/rarity-trait-guide'}>Trait & Rarity Guide</Button>
              </Grid>
            </Grid>
            
            
          </Box>
        </Container>
      <Box className={classes.hudContainer} id="byoa_hud">
      </Box>
        
        
        
              
              
          
            <Container className={classes.speedDial}>
              <DragMove onDragMove={(e : any) => {
                setTranslateDial({
                  x: translateDial.x + e.movementX,
                  y: translateDial.y + e.movementY
                });
                if (e.clientY < 200) {
                  if (dialDirection !== "down") setDialDirection("down");
                }
                if (e.clientY > 200) {
                  if (dialDirection !== "up") setDialDirection("up");
                }
              }}>
                <SpeedDial
                  style={{
                    transform: `translateX(${translateDial.x}px) translateY(${translateDial.y}px)`
                  }}
                  ariaLabel="BYOA Speed Dial"
                  hidden={hiddenDial}
                  icon={<Typography className={classes.byoaButton}>RUN</Typography>}
                  open={openDial}
                  onOpen={() => {
                    //setOpenDial(true);
                  }}
                  onClose={() => {
                    //setOpenDial(false);
                  }}
                  onClick={() => {
                    //gatherDetails(safeTokenId(getQueryStringVal('tokenId')));
                    if(byoaElements.length > 0) {
                      let el = byoaElements[0];
                      if (el.process == undefined) return;
                      el.process([abi['abi'], jrpcProvider],
                        '0xe32acfcedfa8bda1d0ed03cafa854219ce0c9cea', // example
                        ethers,
                        ethers.getDefaultProvider('https://eth-mainnet.alchemyapi.io/v2/Uo717K-DDAxlSM5gXM-zgv678k0aMZH5'),
                        jrpcProvider
                      );
                    }
                  }}
                  direction={dialDirection}
                >
                  

                </SpeedDial>
              </DragMove>
                
            </Container>
            
        </Box>
      </ThemeProvider>
  );
}

export default App;
